.canvas-outer {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12);
  height: 600px;
}

.canvas {
  width: 100%;
  height: 100%;
  position: relative;
}

.canvas__inner {
  width: 100% !important;
  height: 100% !important;
  border: 1px solid #eee;
  position: absolute;
  top: 0;
  left: 0;
}

.options {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12);
  height: 600px;
  overflow-y: auto;
}

/* 字段样式 */
.field {
  margin-bottom: 24px;
}

.field__label {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin-bottom: 8px;
}

/* 颜色选择器样式 */
.field__color {
  display: flex;
  align-items: center;
  gap: 10px;
}

.field__color-button {
  width: 36px;
  height: 36px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 0;
  position: relative;
  overflow: hidden;
}

.field__color input[type="color"] {
  width: 36px;
  height: 36px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0;
  cursor: pointer;
}

.field__input-text {
  width: calc(100% - 46px);
  height: 36px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0 8px;
  font-size: 14px;
}

/* 范围滑块样式 */
.field__range {
  position: relative;
  padding: 10px 0;
}

input[type="range"] {
  width: 100%;
  height: 4px;
  -webkit-appearance: none;
  background: #e0e0e0;
  border-radius: 2px;
  outline: none;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  background: #007bff;
  border-radius: 50%;
  cursor: pointer;
  transition: background .15s ease-in-out;
}

input[type="range"]::-webkit-slider-thumb:hover {
  background: #0056b3;
}

.field__range-value {
  position: absolute;
  right: 0;
  top: -20px;
  font-size: 14px;
  color: #666;
}

/* 按钮组样式 */
.save {
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
}

.edit__button, .save__button {
  flex: 1;
  height: 36px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.edit__button {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #ddd;
}

.edit__button:hover:not(.inactive-btn) {
  background: #e9ecef;
}

.save__button {
  background: #007bff;
  color: white;
}

.save__button:hover:not(.inactive-btn) {
  background: #0056b3;
}

.inactive-btn {
  opacity: 0.5;
  cursor: not-allowed;
}

/* 分组标题 */
.options-group {
  margin-bottom: 24px;
}

.options-group__title {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
}

/* 滚动条样式 */
.options::-webkit-scrollbar {
  width: 6px;
}

.options::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.options::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 3px;
}

.options::-webkit-scrollbar-thumb:hover {
  background: #999;
}

/* 添加介绍文字的样式 */
.signature-intro {
  margin-top: 40px;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12);
}

.signature-intro h2 {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
}

.signature-intro h3 {
  font-size: 20px;
  font-weight: 500;
  color: #333;
  margin: 24px 0 16px;
}

.signature-intro p {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
  margin-bottom: 16px;
}