.App{
  font-family:sans-serif;
  
}

.nav-button {
  background-color: #8214B6;
  color: white;
  transition: all 0.3s ease;
}

.nav-button:hover {
  background-color: #6a1093;
  transform: translateY(-1px);
}

.dropdown-toggle {
  color: #333 !important;
  font-weight: 500;
  padding: 8px 16px;
}

.dropdown-menu {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.dropdown-item {
  padding: 8px 16px;
  color: #333;
  transition: all 0.2s ease;
}

.dropdown-item:hover {
  background-color: #f8f0fc;
  color: #8214B6;
}


